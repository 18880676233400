import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { UtilService } from 'src/app/services/util.service';
import * as moment from 'moment'
import singularDate from 'src/app/models/general/singularDate';
import dateObj from 'src/app/models/general/dateObj';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  public maxYearsLock = 2
  public fecha: string
  public callback: (fecha: Date) => void;
  public startDate: Date;
  public endDate: Date;
  public dateLimit: Date;
  public startLimit: boolean;
  public endLimit: boolean;
  public currentEvents: singularDate[] = [];
  public pedirVacaciones: boolean;

  constructor(
    public popoverController: PopoverController,
    private navParams: NavParams,
    private utils: UtilService,
  ) { 

    this.callback = null
    this.startDate = null
    this.endDate = null
    this.dateLimit = null
    this.startLimit = false
    this.endLimit = false
    this.pedirVacaciones = false

  }

  public ngOnInit() {

    if (this.navParams.data.callback) {
      this.callback = this.navParams.data.callback;
    }

    if (this.navParams.data.startDate) {
      this.startDate = this.utils.stringToFecha(this.navParams.data.startDate);
    }

    if (this.navParams.data.endDate) {
      this.endDate = this.utils.stringToFecha(this.navParams.data.endDate);
    }

    if (this.navParams.data.dateLimit) {
      this.dateLimit = this.utils.stringToFecha(this.navParams.data.dateLimit);
    }

    if (this.navParams.data.startLimit) {
      this.startLimit = this.navParams.data.startLimit;
    }

    if (this.navParams.data.endLimit) {
      this.endLimit = this.navParams.data.endLimit;
    }

    if (this.navParams.data.events) {
      this.currentEvents = this.navParams.data.events
    }

    if (this.navParams.data.pedirVacaciones) {
      this.pedirVacaciones = this.navParams.data.pedirVacaciones
    }

    this.lockDates();

  }

  public onDaySelect(date: dateObj) {

    let dateSelected = moment({ year: date.year, month: date.month, date: date.date }).toDate()

    if (this.dateLimit != null && this.startLimit) {
      if (dateSelected >= this.dateLimit) {
        if (this.pedirVacaciones) {
          if (!this.isDateOnEvent(dateSelected)) {
            this.callback(dateSelected)
            this.popoverController.dismiss()
          }
        } else {
          this.callback(dateSelected)
          this.popoverController.dismiss()
        }
      }
    } else if (this.dateLimit != null && this.endLimit) {
      if (dateSelected <= this.dateLimit) {
        this.callback(dateSelected)
        this.popoverController.dismiss()
      }
    } else {
      if (this.pedirVacaciones) {
        if (!this.isDateOnEvent(dateSelected)) {
          this.callback(dateSelected)
          this.popoverController.dismiss()
        }
      } else {
        this.callback(dateSelected)
        this.popoverController.dismiss()
      }
    }
  }

  private isDateOnEvent(date: Date): boolean {
    for (let i = 0; i < this.currentEvents.length; i++) {
      let evento = this.currentEvents[i];
      if (date.getDate() == evento.date && date.getMonth() == evento.month && date.getFullYear() == evento.year && !evento.eventClass.includes("DiaFestivo") && !evento.eventClass.includes("bajas") && !evento.eventClass.includes("vacaciones") && !evento.eventClass.includes("permisos")) {
        return true;
      }
    }
    return false;
  }

  public async lockDates() {

    let currentEventsOriginal: singularDate[] = JSON.parse(JSON.stringify(this.currentEvents));
    let addClassDisable = (y, m, d) => {
      let encontrado = false;
      for (let i = 0; i < currentEventsOriginal.length && !encontrado; i++) {
        let event = currentEventsOriginal[i];
        if (event.year == y && event.month == m && event.date == d) {
          let eliminado = false;
          encontrado = true;
          for (let j = 0; j < this.currentEvents.length && !eliminado; j++) {
            let cEvent = this.currentEvents[j];
            if (cEvent.year == y && cEvent.month == m && cEvent.date == d) {
              this.currentEvents.splice(j, 1)
              eliminado = true
            }
          }
        }
      }
      this.currentEvents.push({ year: y, month: m, date: d, eventClass: 'disableDay' });
    }
    if (this.dateLimit != null && this.dateLimit != undefined && this.startLimit) {
      let yearLock: number = this.dateLimit.getFullYear();
      let monthLock: number = this.dateLimit.getMonth();
      let dayLock: number = this.dateLimit.getDate();
      let month: number = 0;
      if (this.startDate != null) {
          this.maxYearsLock = moment().year() - this.startDate.getFullYear();
      }
      let year: number = yearLock - this.maxYearsLock;

      while (year < yearLock || (month <= monthLock && year <= yearLock)) {

        let monthDays = moment({ year: year, month: month }).daysInMonth();

        if (month < monthLock || year < yearLock) {
          for (let i = 1; i <= monthDays; i++) {
            addClassDisable(year, month, i)
          }
        } else {
          for (let i = 1; i < dayLock; i++) {
            addClassDisable(year, month, i)
          }
        }
        if (month == 11) {
          year += 1;
          month = 0;
        } else {
          month += 1;
        }
      }
    }

    if (this.dateLimit != null && this.dateLimit != undefined && this.endLimit) {
      let yearLock: number = this.dateLimit.getFullYear();
      let monthLock: number = this.dateLimit.getMonth();
      let dayLock: number = this.dateLimit.getDate();
      let month: number = monthLock;
      let year: number = yearLock;
      if (this.endDate != null) {
        this.maxYearsLock = this.endDate.getFullYear() - moment().year();
      }
      let maxYearLock = yearLock + this.maxYearsLock;
      let diasMesLock = moment(this.dateLimit).daysInMonth();

      while ((year > yearLock || (month >= monthLock && year >= yearLock)) && year <= maxYearLock) {

        let monthDays = moment({ year: year, month: month }).daysInMonth();

        if (month > monthLock || year > yearLock) {
          for (let i = 1; i <= monthDays; i++) {
            addClassDisable(year, month, i)
          }
        } else {
          for (let i = dayLock + 1; i <= diasMesLock; i++) {
            addClassDisable(year, month, i)
          }
        }
        if (month == 11) {
          year += 1;
          month = 0;
        } else {
          month += 1;
        }
      }
    }

  }  

}
