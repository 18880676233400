import { Pipe } from '@angular/core';

@Pipe({
  name: 'monthName'
})
export class MonthNamePipe {
  //private lang: string; // (es or en)
  transform(value, args) {
    // if (args === 'es') { this.lang = 'es'; }
    // let monthNames = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
    // if (this.lang === 'es') {
    let monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    // }
    return monthNames[value - 1];
  }
}
