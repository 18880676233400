import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CalendarComponent } from './calendar/calendar.component';
import { FvCalendarComponent } from './fv-calendar/fv-calendar.component';
import { ModalComponent } from './modal/modal.component';
import { PipeModule } from 'src/app/pipes/pipe.module';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { OrientationComponent } from './orientation/orientation.component';
import { TabsPublicComponent } from './tabs-public/tabs-public.component';
import { TabsPrivateComponent } from './tabs-private/tabs-private.component';
import { MenuComponent } from './menu/menu.component';
import { RouterModule } from '@angular/router';


@NgModule({
    declarations: [
      TabsPublicComponent,
      TabsPrivateComponent,
      ModalComponent,
      FvCalendarComponent,
      CalendarComponent,
      LoaderComponent,
      OrientationComponent,
      MenuComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        PipeModule,
        FormsModule,
        RouterModule
    ],
    exports: [
      TabsPublicComponent,
      TabsPrivateComponent,
      ModalComponent,
      FvCalendarComponent,
      CalendarComponent,
      LoaderComponent,
      OrientationComponent,
      MenuComponent
    ]
})

export class ComponentsModule { }
