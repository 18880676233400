import ClienteModel from "../cliente/cliente.model"
import AlbaranLineasModel from "../albaranLineas/albaranLineas.model"
import SerieModel from "../serie/serie.model"
import PresupuestoModel from "../presupuesto/presupuesto.model"

export default class AlbaranModel {
    public id: number
    public presupuestoId : number
    public presupuesto : PresupuestoModel
    public referencia : string
    public origen : string
    public razonSocialEmpresa: string
    public codigo : string
    public direccionEmpresa: string
    public direccionCliente: string
    public nifEmpresa : string
    public nifCliente : string
    public fecha : string
    public subTotal: number
    public base : string
    public iva : string
    public total : string
    public serie: SerieModel = new SerieModel()
    public cliente : ClienteModel = new ClienteModel()
    public lineasAlbaran: AlbaranLineasModel[] = []
    public notasCliente : string
    public descuentoConcepto : string
    public descuentoPorcentaje : string
    public descuentoImporte : number
    public sociedadId: number
}