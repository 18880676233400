import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemoveWhitespacesPipe } from './pipes/remove-whitespaces.pipe';
import { TruncateStringPipe } from './pipes/truncate-string.pipe';
import { DateStringPipe } from './pipes/date-string.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { MonthNamePipe } from './pipes/month-name.pipe';
import { PuntosMilesPipe } from './pipes/puntos-miles.pipe';

@NgModule({
  declarations: [RemoveWhitespacesPipe, TruncateStringPipe, DateStringPipe, SafeUrlPipe, SafeHtmlPipe, MonthNamePipe, PuntosMilesPipe],
  exports: [RemoveWhitespacesPipe, TruncateStringPipe, DateStringPipe, SafeUrlPipe, SafeHtmlPipe, MonthNamePipe, PuntosMilesPipe],
  imports: [
    CommonModule
  ]
})
export class PipeModule { }
