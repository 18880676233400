import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { ApiService } from "src/app/api/api.service";
import ClienteModel from "src/app/models/cliente/cliente.model";
import { AlertService } from "src/app/services/alert.service";
import { LoaderService } from "src/app/services/loader.service";

@Injectable({
    providedIn: 'root'
})
export class ClientesFormResolver implements Resolve<ClienteModel> {
    constructor(
        public api: ApiService,
        private alertService: AlertService,
        private loaderService: LoaderService
    ) { }

    public async resolve(route: ActivatedRouteSnapshot): Promise<ClienteModel> {
        try {
            await this.loaderService.show('Cargando...');
            let id = parseInt(route.paramMap.get('id'), 10)
            if (id > 0) {
                return await this.api.Clientes.getById(id)
            } else {
                return new ClienteModel()
            }
        } catch (ex) {
            if (ex != null && ex.message != null) {
                this.alertService.showMessage(ex.message, '¡Atención!')
            }
            throw ex
        } finally {
            await this.loaderService.hide()
        }
    }
}
