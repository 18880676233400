import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilService } from '../../services/util.service';

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {

  constructor(
    private sanitized: DomSanitizer
  ){ }

  transform(value: any, ...args: any[]): any {
    if (!UtilService.isNullOrUndefined(value)) {
      return this.sanitized.bypassSecurityTrustUrl(value);
    } else {
      return '';
    }
  }

}
