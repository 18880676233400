import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthguardGuard } from './guards/authguard.guard';
import { AlbaranesFormResolver } from './pages/albaranes/albaranes-form/albaranes-form.resolver';
import { ClientesFormResolver } from './pages/clientes/clientes-form/clientes-form.resolver';
import { PresupuestosFormResolver } from './pages/presupuestos/presupuestos-form/presupuestos-form.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'recovery',
    loadChildren: () => import('./pages/recovery/recovery.module').then( m => m.RecoveryPageModule)
  },
  {
    path: 'recovery-confirm',
    loadChildren: () => import('./pages/recovery-confirm/recovery-confirm.module').then( m => m.RecoveryConfirmPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: '',
    canActivateChild: [AuthguardGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
      },
      {
        path: 'clientes-list',
        loadChildren: () => import('./pages/clientes/clientes-list/clientes-list.module').then( m => m.ClientesListPageModule)
      },
      {
        path: 'clientes-form/:id',
        resolve: {
          cliente: ClientesFormResolver
        },
        loadChildren: () => import('./pages/clientes/clientes-form/clientes-form.module').then( m => m.ClientesFormPageModule)
      },
      {
        path: 'presupuestos-list',
        loadChildren: () => import('./pages/presupuestos/presupuestos-list/presupuestos-list.module').then( m => m.PresupuestosListPageModule)
      },
      {
        path: 'presupuestos-form/:id',
        resolve: {
          presupuesto: PresupuestosFormResolver
        },
        loadChildren: () => import('./pages/presupuestos/presupuestos-form/presupuestos-form.module').then( m => m.PresupuestosFormPageModule)
      },
      {
        path: 'albaranes-list',
        loadChildren: () => import('./pages/albaranes/albaranes-list/albaranes-list.module').then( m => m.AlbaranesListPageModule)
      },
      {
        path: 'albaranes-form/:id',
        resolve: {
          albaran: AlbaranesFormResolver
        },
        loadChildren: () => import('./pages/albaranes/albaranes-form/albaranes-form.module').then( m => m.AlbaranesFormPageModule)
      },
      {
        path: 'buscador-productos-modal',
        loadChildren: () => import('./pages/presupuestos/modals/buscador-productos-modal/buscador-productos-modal.module').then( m => m.BuscadorProductosModalPageModule)
      },
      {
        path: 'buscador-clientes-modal',
        loadChildren: () => import('./pages/presupuestos/modals/buscador-clientes-modal/buscador-clientes-modal.module').then( m => m.BuscadorClientesModalPageModule)
      },
      {
        path: 'direcciones-presupuesto-modal',
        loadChildren: () => import('./pages/presupuestos/modals/direcciones-presupuesto-modal/direcciones-presupuesto-modal.module').then( m => m.DireccionesPresupuestoModalPageModule)
      },
      {
        path: 'clientes-form-modal',
        loadChildren: () => import('./pages/clientes/clientes-form-modal/clientes-form-modal.module').then( m => m.ClientesFormModalPageModule)
      },
      {
        path: 'mi-cuenta',
        loadChildren: () => import('./pages/mi-cuenta/mi-cuenta.module').then( m => m.MiCuentaPageModule)
      },
      {
        path: 'albaranes-form-fotos-modal',
        loadChildren: () => import('./pages/albaranes/albaranes-form-fotos-modal/albaranes-form-fotos-modal.module').then( m => m.AlbaranesFormFotosModalPageModule)
      },
      {
        path: 'lineas-albaranes-modal',
        loadChildren: () => import('./pages/albaranes/modals/lineas-albaranes-modal/lineas-albaranes-modal.module').then( m => m.LineasAlbaranesModalPageModule)
      },
      {
        path: 'email-pdf-modal',
        loadChildren: () => import('./pages/albaranes/modals/email-pdf-modal/email-pdf-modal.module').then( m => m.EmailPdfModalPageModule)
      },
      {
        path: 'presupuestos-detalle/:id',
        resolve: {
          presupuesto: PresupuestosFormResolver
        },
        loadChildren: () => import('./pages/presupuestos/presupuestos-detalle/presupuestos-detalle.module').then( m => m.PresupuestosDetallePageModule)
      },
      {
        path: 'clientes-detalle',
        loadChildren: () => import('./pages/clientes/clientes-detalle/clientes-detalle.module').then( m => m.ClientesDetallePageModule)
      },
      {
        path: 'buscador-direcciones-modal',
        loadChildren: () => import('./pages/presupuestos/modals/buscador-direcciones-modal/buscador-direcciones-modal.module').then( m => m.BuscadorDireccionesModalPageModule)
      },
      {
        path: 'nuevo-cliente-modal',
        loadChildren: () => import('./pages/presupuestos/modals/nuevo-cliente-modal/nuevo-cliente-modal.module').then( m => m.NuevoClienteModalPageModule)
      },
      {
        path: 'buscador-provincias-modal',
        loadChildren: () => import('./generico/modals/buscador-provincias-modal/buscador-provincias-modal.module').then( m => m.BuscadorProvinciasModalPageModule)
      },
      {
        path: 'buscador-paises-modal',
        loadChildren: () => import('./generico/modals/buscador-paises-modal/buscador-paises-modal.module').then( m => m.BuscadorPaisesModalPageModule)
      },
      {
        path: 'lineas-presupuestos-modal',
        loadChildren: () => import('./pages/presupuestos/modals/lineas-presupuestos-modal/lineas-presupuestos-modal.module').then( m => m.LineasPresupuestosModalPageModule)
      },
      {
        path: 'filtro-presupuestos-list-modal',
        loadChildren: () => import('./pages/presupuestos/modals/filtro-presupuestos-list-modal/filtro-presupuestos-list-modal.module').then( m => m.FiltroPresupuestosListModalPageModule)
      },
      {
        path: 'enviar-email-modal',
        loadChildren: () => import('./pages/presupuestos/modals/enviar-email-modal/enviar-email-modal.module').then( m => m.EnviarEmailModalPageModule)
      },
      {
        path: 'cambiar-estado-presupuesto-modal',
        loadChildren: () => import('./pages/presupuestos/modals/cambiar-estado-presupuesto-modal/cambiar-estado-presupuesto-modal.module').then( m => m.CambiarEstadoPresupuestoModalPageModule)
      },
      {
        path: 'resumen-presupuesto-modal',
        loadChildren: () => import('./pages/presupuestos/modals/resumen-presupuesto-modal/resumen-presupuesto-modal.module').then( m => m.ResumenPresupuestoModalPageModule)
      },{
        path: 'resumen-albaranes-modal',
        loadChildren: () => import('./pages/albaranes/modals/resumen-albaranes-modal/resumen-albaranes-modal.module').then( m => m.ResumenAlbaranesModalPageModule)
      },
      {
        path: 'fecha-inicio-facturacion-modal',
        loadChildren: () => import('./pages/presupuestos/modals/fecha-inicio-facturacion-modal/fecha-inicio-facturacion-modal.module').then( m => m.FechaInicioFacturacionModalPageModule)
      },
    ]},
    
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
