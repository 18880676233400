import ComboModel from "../general/combo.model"
import DireccionModel from "./direccion.model"
import OrigenModel from "./origen.model"

export default class ClienteModel {
    public id: number = 0
    public tipo: string = "empresa"
    public razonSocial: string = ""
    public nif: string = ""
    public nombre: string = ""
    public apellidos: string = ""
    public email: string = ""
    public telefono: string = ""
    public origen: ComboModel = new ComboModel()
    public otroOrigen: string = ""
    public direcciones: DireccionModel[] = []
    public iniciales: string
    public sociedad: ComboModel = new ComboModel()
}