import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(
    private sanitized: DomSanitizer
  ){ }

  transform(value: any, ...args: any[]): any {
    if (!UtilService.isNullOrUndefined(value)) {
      return this.sanitized.bypassSecurityTrustHtml(value);
    } else {
      return '';
    }
  }

}
