import { GlobalService } from "src/app/services/global.service"
import ClienteModel from "../cliente/cliente.model"
import FicheroModel from "../ficheros/fichero.model"
import ComboModel from "../general/combo.model"
import IVAModel from "../general/iva.model"
import DireccionPresupuestoModel from "./direccionPresupuesto.model"
import LineaPresupuestoModel from "./lineaPresupuesto.model"

export default class PresupuestoModel {
    public id: number
    public pasoActual: number
    public totalPasos: number = GlobalService.totalPasosPresupuesto
    public referencia: string
    public cliente: ClienteModel = new ClienteModel()
    public tipoPresupuesto: ComboModel = new ComboModel()
    public tipoChecked: string
    public fechaMudanza: Date
    public fechaMudanzaString: string = ''
    public direccionesMudanza: DireccionPresupuestoModel[] = []
    public fechaInicioGuardamuebles: Date 
    public fechaInicioGuardamueblesString: string = ''
    public fechaFinGuardamuebles: Date
    public fechaFinGuardamueblesString: string = ''
    public observaciones: string
    public seguimiento: string
    public incidencias: string
    public lineasPresupuesto: LineaPresupuestoModel[] = []
    public almacenFicherosId: number
    public ficheros: FicheroModel[] = []
    public estado: string
    public estadoId: number
    public fecha: string
    public formaPago: ComboModel = new ComboModel()
    public formaPagoId: number
    public total: number
    public base: number 
    public subTotal: number 
    public descuentoConcepto: string
    public descuentoPorcentaje: number
    public descuentoImporte: number
    public tipoDescuento : string
    public totalIVA: number
    public sociedadId: number
    public serie: ComboModel = new ComboModel()
}