import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import UsuarioModel from 'src/app/models/usuario/usuario.model';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  public api: ApiService;

  constructor(
    public http: HttpClient
  ) { }

  public async getUsuario(id: string): Promise<UsuarioModel> {
    return await this.api.HttpPost('/usuarios/getUsuario', {
      id
    })
  }

  public async cambiarImagen(formData: FormData): Promise<UsuarioModel> {      
    return await this.api.HttpPostConFormData('/usuarios/cambiarimagen', formData)
  }
  
}



