import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {

  public isLoading: Subject<boolean> = this.loaderService.isLoading;
  //public loaderText: Subject<string> = this.loaderService.loaderText;

  constructor(
    public loaderService: LoaderService
  ) { }

  ngOnInit() {}

}
