import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { promise } from 'protractor';
import FicheroModel from 'src/app/models/ficheros/fichero.model';
import PagingModel from 'src/app/models/general/paging.model';
import EstadoPresupuestoModel from 'src/app/models/presupuesto/estadoPresupuesto.model';
import PresupuestoModel from 'src/app/models/presupuesto/presupuesto.model';
import UsuarioModel from 'src/app/models/usuario/usuario.model';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class PresupuestosService {

  public api: ApiService;

  constructor(public http: HttpClient) { }

  public async getById(id: number): Promise<PresupuestoModel> {
    return await this.api.HttpGet('/presupuestos/getbyid', {id});
  }

  public async getAllPaginado(start: number, limit: number, filtro_EstadoId: string, filtro_Tipo: string, filtro_Busqueda: string): Promise<PagingModel<PresupuestoModel>> {
    return await this.api.HttpGet('/presupuestos/getallpaginado', {start, limit, filtro_EstadoId, filtro_Tipo, filtro_Busqueda});
  }

  public async savePresupuesto(presupuesto: PresupuestoModel): Promise<PresupuestoModel> {
    try{
      return await  this.api.HttpPost('/presupuestos/save', presupuesto)
    } catch (error) {
      throw new Error(error.message)
    }
  }

  public async subirFichero(formData: FormData): Promise<FicheroModel[]> {      
    return await this.api.HttpPostConFormData('/presupuestos/subirfichero', formData)
  }

  public async confirmarFicherosTemporales(ficheros: FicheroModel[]): Promise<FicheroModel[]> {
   return this.api.HttpPost('/presupuestos/confirmarficherostemporales', ficheros)
  }

  public async eliminarFicheros(ficheros: FicheroModel[]): Promise<FicheroModel[]> {
    return await  this.api.HttpPost('/presupuestos/eliminarficheros', ficheros)
  }

  public async cambiarEstado(estado: EstadoPresupuestoModel, usuario: UsuarioModel): Promise<EstadoPresupuestoModel> {
    try{
      return await this.api.HttpPost('/presupuestos/postcambiarestado', {estado, usuario})
    } catch (error){
      throw new Error(error.message);
    }
  }
  
  public descargarFichero(id: number): Promise<FicheroModel> {
    return this.api.HttpGet('/presupuestos/descargarfichero', {id});
  }
  
  public enviarEmail(presupuesto: PresupuestoModel, observaciones: string, email: string): Promise<String> {
    return this.api.HttpPost('/presupuestos/enviaremail', {presupuesto, observaciones, email});
  }

}
