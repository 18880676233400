import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { ApiService } from "src/app/api/api.service";
import PresupuestoModel from "src/app/models/presupuesto/presupuesto.model";
import { AlertService } from "src/app/services/alert.service";
import { LoaderService } from "src/app/services/loader.service";
import { UtilService } from "src/app/services/util.service";

@Injectable({
    providedIn: 'root'
})
export class PresupuestosFormResolver implements Resolve<PresupuestoModel> {
    constructor(
        public api: ApiService,
        private alertService: AlertService,
        private loaderService: LoaderService,
        private util: UtilService
    ) { }

    public async resolve(route: ActivatedRouteSnapshot): Promise<PresupuestoModel> {
        try {
            await this.loaderService.show('Cargando...');
            let id = parseInt(route.paramMap.get('id'), 10)
            if (id > 0) {
                let presupuesto = await this.api.Presupuestos.getById(id)
                if (presupuesto.fechaMudanza) presupuesto.fechaMudanzaString = this.util.toISOString(new Date(presupuesto.fechaMudanza))
                if (presupuesto.fechaFinGuardamuebles) presupuesto.fechaFinGuardamueblesString = this.util.toISOString(new Date(presupuesto.fechaFinGuardamuebles))
                if (presupuesto.fechaInicioGuardamuebles) presupuesto.fechaInicioGuardamueblesString = this.util.toISOString(new Date(presupuesto.fechaInicioGuardamuebles))
                return presupuesto;
            } else {
                return new PresupuestoModel()
            }
        } catch (ex) {
            if (ex != null && ex.message != null) {
                this.alertService.showMessage(ex.message, '¡Atención!')
            }
            throw ex
        } finally {
            await this.loaderService.hide()
        }
    }
}
