import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from '../../services/util.service';

@Pipe({
  name: 'removeWhitespaces'
})
export class RemoveWhitespacesPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    return (!UtilService.isNullOrUndefined(value) ? value : '').replace(/ /g, '');
  }

}
