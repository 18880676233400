import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from '../../services/util.service';

@Pipe({
  name: 'truncateString'
})
export class TruncateStringPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    const myValue = (!UtilService.isNullOrUndefined(value) ? value : '');
    const limit = args.length > 0 ? parseInt(args[0], 10) : 10;
    const trail = args.length > 1 ? args[1] : '';
    return myValue.length > limit ? myValue.substring(0, limit) + trail : myValue;
  }

}
