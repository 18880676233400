import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ApiService } from '../api.service';
import ComboModel from 'src/app/models/general/combo.model';
import PaisModel from 'src/app/models/general/pais.model';
import ProvinciaModel from 'src/app/models/general/provincia.model';

@Injectable({
    providedIn: 'root'
})
export class DatosMaestrosService {

    public api: ApiService;
    
    public provincias: ProvinciaModel[]
    public paises: PaisModel[]
    public origenCliente: ComboModel[]
    public tiposPresupuesto: ComboModel[]
    public series: ComboModel[]
    public descuentos : ComboModel[]
    public formasPago : ComboModel[]
    public estadosPresupuesto: ComboModel[]
    public sociedades: ComboModel[]

    constructor(
        public http: HttpClient
    ) { }

       
    public async getProvincias(): Promise<ProvinciaModel[]> {
      if (!this.provincias != null) {
        this.provincias = await this.api.HttpGet('/datosmaestros/getprovincias');        
      }
      return this.provincias;      
    }

    public async getPaises(): Promise<PaisModel[]> {
      if (!this.paises != null) {
        this.paises = await this.api.HttpGet('/datosmaestros/getpaises');        
      }
      return this.paises;      
    }

    public async getOrigenCliente(): Promise<ComboModel[]> {
      if (!this.origenCliente != null) {
        this.origenCliente = await this.api.HttpGet('/datosmaestros/getorigencliente');        
      }
      return this.origenCliente;      
    }

    public async getSociedades(): Promise<ComboModel[]> {
      if (!this.sociedades != null) {
        this.sociedades = await this.api.HttpGet('/datosmaestros/getsociedades');        
      }
      return this.sociedades;      
    }

    public async getTiposPresupuesto(): Promise<ComboModel[]> {
      if (!this.tiposPresupuesto != null) {
        this.tiposPresupuesto = await this.api.HttpGet('/datosmaestros/gettipospresupuesto');        
      }
      return this.tiposPresupuesto;   
    }

    public async getEstadosPresupuesto(presupuestoId: number): Promise<ComboModel[]> {
      if (!this.estadosPresupuesto != null) {
        this.estadosPresupuesto = await this.api.HttpGet('/datosmaestros/getestadospresupuesto', {presupuestoId});        
      }
      return this.estadosPresupuesto;   
    }

    public async getSeriesAlbaranes(): Promise<ComboModel[]> {
      if (!this.series != null) {
        this.series = await this.api.HttpGet('/datosmaestros/getseriesalbaranes');        
      }
      return this.series;      
    }

    public async getSeriesPresupuestos(): Promise<ComboModel[]> {
      if (!this.series != null) {
        this.series = await this.api.HttpGet('/datosmaestros/getseriespresupuestos');        
      }
      return this.series;      
    }

    public async getDescuentos(): Promise<ComboModel[]> {
      if (!this.descuentos != null) {
        this.descuentos = await this.api.HttpGet('/datosmaestros/getdescuentos');        
      }
      return this.descuentos;      
    }

    public async getFormasPago(): Promise<ComboModel[]> {
      if (!this.formasPago != null) {
        this.formasPago = await this.api.HttpGet('/datosmaestros/getformaspago');        
      }
      return this.formasPago;      
    }

}
