import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrientationService } from 'src/app/services/orientation.service';

@Component({
  selector: 'app-orientation',
  templateUrl: './orientation.component.html',
  styleUrls: ['./orientation.component.scss'],
})
export class OrientationComponent implements OnInit {

  constructor(
    public orientation: OrientationService,
    private modalController: ModalController
  ) { }

  public dismiss$ = new Subject<void>();
  public correctOrientation = false;

  ngOnInit(): void {
    this.orientation.currentOrientation$
      .pipe(takeUntil(this.dismiss$))
      .subscribe({
        next: value => (this.correctOrientation = this.isCorrect(value)),
        complete: () => this.modalController.dismiss()
      });
  }

  orientationString(): string {
    return this.orientation.lockedOrientation$.value.split("-")[0];
  }

  /**
   * Checks if the current orientation matches the locked orientation
   */
  isCorrect(value: OrientationType): boolean {
    return value === this.orientation.lockedOrientation$.value;
  }

  /**
   * Dismisses the modal if orientation has been set
   */
  continueClicked(): void {
    const lock = this.orientation.lockedOrientation$.value;
    this.orientation.setOrientation(lock).then(() => this.dismiss$.next());
  }

}
