import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import ClienteModel from 'src/app/models/cliente/cliente.model';
import PagingModel from 'src/app/models/general/paging.model';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class ClientesService {
  public api: ApiService;

  constructor(public http: HttpClient) {}

  public getById(id: number): Promise<ClienteModel> {
    return this.api.HttpGet('/clientes/getbyid', {id});
  }

  public getAllClientesPaginado(start: number, limit: number, filtro_Busqueda: string): Promise<PagingModel<ClienteModel>> {
    return this.api.HttpGet('/clientes/getallclientespaginado', {start, limit, filtro_Busqueda});
  }

  public getComprobarNif(nif: string, clienteId: number): Promise<string> {
    return this.api.HttpGet('/clientes/getcomprobarnif', {nif, clienteId});
  }

  public saveData(cliente: ClienteModel): Promise<ClienteModel> {
    return this.api.HttpPost('/clientes/postsavedata', cliente)
  }
}
