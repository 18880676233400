import { Component, OnInit } from '@angular/core';
import { relativeTimeThreshold } from 'moment';
import { ApiService } from 'src/app/api/api.service';
import { GlobalService } from 'src/app/services/global.service';
import { AlertService } from 'src/app/services/alert.service';
import { NavController } from '@ionic/angular';
import { ToastService } from 'src/app/services/toast.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  
  public appPages = [];

  constructor(public api: ApiService, private navCtrl: NavController, private toastService: ToastService, private alertService: AlertService) {
   }

  ngOnInit() {

    this.appPages = [
      {
        title: 'Panel',
        url: '/home',
        icon: 'home',
        visible: true
      },
      {
        title: 'Cuidadores',
        url: '/cuidadores-listado',
        icon: 'medical',
        visible: true
      },
      {
        title: 'Pacientes',
        url: '/pacientes-listado',
        icon: 'people',
        visible: true
      },
      {
        title: 'Alertas',
        url: '/medicos-alertas',
        icon: 'notifications',
        visible: true
      }
    ];

    let pages = JSON.parse(JSON.stringify(this.appPages));
      this.appPages = [];
      for (let i = 0; i < pages.length; i++) {
        if (pages[i].visible) {
          this.appPages.push(pages[i]);
        }
      }
  }

  async goDetalleMedico() {
    this.navCtrl.navigateRoot('medico-detalle');
  }

  async logOut() {
    if (await this.alertService.showConfirm('', '¿Deseas cerrar sesión?', 'Cerrar sesión', 'Cancelar')) {
      try {
        await this.api.Login.logOut(true);
        this.navCtrl.navigateRoot('login-principal');
      } catch (ex) {
        this.toastService.showToastError('Error al cerrar sesion');
      }
    }
  }


}
