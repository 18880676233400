import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import AlbaranModel from 'src/app/models/albaran/albaran.model';
import FicheroModel from 'src/app/models/ficheros/fichero.model';
import PagingModel from 'src/app/models/general/paging.model';
import UsuarioModel from 'src/app/models/usuario/usuario.model';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class AlbaranesService {

  public api: ApiService;

  constructor(public http: HttpClient) { }

  public getById(id: number): Promise<AlbaranModel> {
    return this.api.HttpGet('/albaranes/getbyid', {id});
  }

  public getAllAlbaranes(start: number, limit: number, filtro_Busqueda: string): Promise<PagingModel<AlbaranModel>> {
    return this.api.HttpGet('/albaranes/getallpaginado', {start, limit, filtro_Busqueda});
  }

  public descargarFichero(id: number): Promise<FicheroModel> {
    return this.api.HttpGet('/albaranes/descargarfichero', {id});
  }
  
  public saveData(albaran: AlbaranModel): Promise<AlbaranModel> {
    return this.api.HttpPost('/albaranes/postsave', albaran);
  }

  public enviarEmail(albaran: AlbaranModel, nombreCompleto: string, email: string): Promise<String> {
    return this.api.HttpPost('/albaranes/enviaremail', {albaran, nombreCompleto, email});
  }

}
