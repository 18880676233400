import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { NavParamsService } from 'src/app/services/nav-params.service';
import UsuarioModel from 'src/app/models/usuario/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  checkLogin() {
    throw new Error('Method not implemented.');
  }

  public api: ApiService;
  public timeoutCheckToken: any;

  constructor(
    public http: HttpClient,
    private navParams: NavParamsService
  ) { }

  public async login(userName: string, password: string): Promise<UsuarioModel> {
    try {
      let resp: UsuarioModel = await this.api.HttpPost('/login/postLogin', { userName, password });
      let doLogin = await this.doLoginUsuario(resp);
      if (doLogin) {
        return resp;
      } else {
        return
      }
    } catch (error) {
      throw new Error(error.message)
    }
    
  }

  public async getCurrentUser(): Promise<UsuarioModel> {
    if(localStorage.getItem('token') != null && localStorage.getItem('token') != '') {
      this.api.loginToken = localStorage.getItem('token')
    }
    return await this.api.HttpGet('/login/getCurrentUser');
  }

  public activarCheckToken() {

    // if (this.timeoutCheckToken != null) {
    //   clearInterval(this.timeoutCheckToken);
    // }
    // this.timeoutCheckToken = setInterval(async () => {
    //   // ahora lo pensamos
    //   let valid = await this.checkToken();
    //   if (!valid) {
    //     this.logOut(false);
    //     this.toastService.showToastError("Sesión perdida por inactividad.")
    //     this.modalService.dismissAllModals();
    //     this.navCtrl.navigateRoot('login');
    //   } else {
    //     await this.api.Notificaciones.getNumNotificacionesNoVistas();
    //   }
    // }, 30000)
  }

  public desactivarCheckToken() {
    if (this.timeoutCheckToken != null) {
      clearInterval(this.timeoutCheckToken);
    }
  }

  public async checkToken(): Promise<boolean> {
    return await this.api.HttpPost('/login/checkToken')
  }  

  private async doLoginUsuario(usuario: UsuarioModel): Promise<boolean> {
    try {
      if (usuario) {
        this.api.loginToken = usuario.token;
        this.api.logged = true;
        localStorage.setItem('token', usuario.token);
        this.api.usuario = usuario;
        return true;
      } else {
        return false;
      }
    } catch (error) {
      throw (new Error(error.message))
    }
  }
 

  public async logOut(removePushToken: boolean): Promise<void> {
    this.api.usuario = null;
    this.api.loginToken = null;
    this.api.logged = false;
    localStorage.removeItem('token')
    //if (clearDni) localStorage.removeItem('dni');
    if (removePushToken) {
      let pushToken = window["pushToken"]
      if (pushToken != null) {
        try {
          await this.api.WebPush.removeToken(pushToken)
        } catch (ex) {
          console.log('No se pudo eliminar el token de notificaciones PUSH porque el servicio devolvió error.')
        }
      }
    }
    this.navParams.clear();
    this.desactivarCheckToken();
    return
  }

  public saveData(usuario: UsuarioModel): Promise<UsuarioModel> {
    return this.api.HttpPost('/login/postsavedata', usuario)
  }

  public recovery(mail: string): Promise<boolean> {
    return this.api.HttpPost('/login/recovery', {mail})
  }

  public checkHash(mail: string, hash: string): Promise<boolean> {
    return this.api.HttpPost('/login/checkhash', {mail, hash})
  }

  public resetPassword(mail: string, password: string, hash: string): Promise<boolean> {
    return this.api.HttpPost('/login/resetpassword', {mail, password, hash})
  }

}
