import { HttpHandler, HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import ResultClass from '../models/general/result.model';
import UsuarioModel from '../models/usuario/usuario.model';
import { ConfigService } from '../services/config.service';
import { AlbaranesService } from './albaranes/albaranes.service';
import { ClientesService } from './clientes/clientes.service';
import { DatosMaestrosService } from './datos-maestros/datos-maestros.service.ts.service';
import { FicherosService } from './ficheros/ficheros.service';
import { LoginService } from './login/login.service';
import { PresupuestosService } from './presupuestos/presupuestos.service';
import { ProductosService } from './productos/productos.service';
import { UsuariosService } from './usuarios/usuarios.service';
import { WebPushService } from './web-push/web-push.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public url: string;
  public loginToken: string;
  public logged: boolean;
  public usuario: UsuarioModel;

  constructor(
    private router: Router,
    public config: ConfigService,
    public http: HttpClient,
    public httpHandler: HttpHandler,
    public Usuarios: UsuariosService,
    public Login: LoginService,
    public Clientes: ClientesService,
    public Albaranes: AlbaranesService,
    public Presupuestos: PresupuestosService,
    public DatosMaestros: DatosMaestrosService,
    public Productos: ProductosService,
    public WebPush: WebPushService,
    public Ficheros: FicherosService
  ) {
    this.url = config.getUrlService();
    this.Login.api = this;
    this.Usuarios.api = this;
    this.WebPush.api = this;
    this.Clientes.api = this;
    this.Albaranes.api = this;
    this.Presupuestos.api = this;
    this.DatosMaestros.api = this;
    this.Productos.api = this;
    this.Ficheros.api = this;
  }

  public async HttpGet<T>(url: string, params?: any, baseUrl?: string, checkLogin?: boolean): Promise<T> {
    return new Promise<T>(async (resolve, reject) => {
      let headers = new HttpHeaders()
      headers = headers.append('Content-Type', 'application/json')

      if (this.loginToken != null) {
        headers = headers.append('Access-Token', this.loginToken)
      }

      if (baseUrl == null) baseUrl = this.url;

      params = params || {}
      let httpParams = new HttpParams()
      for (let param in params) {
        if (params[param] != null) {
          httpParams = httpParams.append(param, params[param].toString())
        } else {
          httpParams = httpParams.append(param, '')
        }
      }

      const options = {
        headers,
        withCredentials: true,
        params: httpParams
      }

      this.http.get<ResultClass<T>>(baseUrl + url, options).subscribe(data => {
        if (data.Error && data.Error.HasError) {
          reject(new Error(data.Error.Message))
        } else {
          resolve(data.content)
        }
      }, (err: HttpErrorResponse) => {
        if (err != null && err.status != null && err.status == 401) {
          this.logged = false
          this.router.navigate(['/login'], {
            queryParams: {
              returnURL: location.href
            }
          })
        }
        if (err != null && err.error != null && err.error.Error != null && err.error.Error.Message != null) {
          reject(new Error(err.error.Error.Message))
        } else {
          reject(new Error('Se ha producido un error.'))
        }
      })

    })
  }

  public async HttpPost<T>(url: string, params?: any, baseUrl?: string, checkLogin?: boolean): Promise<T> {
    return new Promise<T>(async (resolve, reject) => {
      let headers = new HttpHeaders()
      headers = headers.append('Content-Type', 'application/json')

      if (this.loginToken != null) {
        headers = headers.append('Access-Token', this.loginToken)
      }

      if (baseUrl == null) baseUrl = this.url;

      params = params || {}
      const options = {
        headers,
        withCredentials: true
      }

      this.http = new HttpClient(this.httpHandler);

      this.http.post<ResultClass<T>>(baseUrl + url, JSON.stringify(params), options).subscribe(data => {
        if (data.Error && data.Error.HasError) {
          reject(new Error(data.Error.Message))
        } else {
          resolve(data.content)
        }
      }, (err: HttpErrorResponse) => {
        if (err != null && err.status != null && err.status == 401) {
          this.logged = false
          this.router.navigate(['/login'], {
            queryParams: {
              returnURL: location.href
            }
          })
        }
        if (err != null && err.error != null && err.error.Error != null && err.error.Error.Message != null) {
          reject(new Error(err.error.Error.Message))
        } else {
          reject(new Error('Se ha producido un error.'))
        }
      })

    })
  }

  public HttpPostConFormData<T>(url: string, params: FormData): Promise<T> {
    return new Promise<T>(async (resolve, reject) => {
      let headers = new HttpHeaders()

      if (this.loginToken != null) {
        headers = headers.append('Access-Token', this.loginToken)
      }

      // if (baseUrl == null) baseUrl = this.url;
      let baseUrl = this.url;

      // params = params || {}

      const body = params;

      const options = {
        headers,
        withCredentials: true
      }

      this.http = new HttpClient(this.httpHandler);

      this.http.post<ResultClass<any>>(baseUrl + url, body, options).subscribe(async data => {
        if (data.Error && data.Error.HasError) {
          reject(new Error(data.Error.Message))
        } else {
          resolve(data.content)
        }
      }, (err: HttpErrorResponse) => {
        if (err != null && err.status != null && err.status == 401) {
          this.logged = false
          this.router.navigate(['/login'], {
            queryParams: {
              returnURL: location.href
            }
          })
        }
        if (err != null && err.error != null && err.error.Error != null && err.error.Error.Message != null) {
          reject(new Error(err.error.Error.Message))
        } else {
          reject(new Error('Se ha producido un error.'))
        }
      })

    })
  }
}
