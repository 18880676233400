import { Injectable } from '@angular/core';
import AlbaranModel from '../models/albaran/albaran.model';
import AlbaranLineasModel from '../models/albaranLineas/albaranLineas.model';
import ClienteModel from '../models/cliente/cliente.model';
import DireccionModel from '../models/cliente/direccion.model';
import FicheroModel from '../models/ficheros/fichero.model';
import EmailModel from '../models/general/email.model';
import DireccionPresupuestoModel from '../models/presupuesto/direccionPresupuesto.model';
import EstadoPresupuestoModel from '../models/presupuesto/estadoPresupuesto.model';
import FiltroPresupuestoModel from '../models/presupuesto/filtroPresupuesto.model';
import LineaPresupuestoModel from '../models/presupuesto/lineaPresupuesto.model';
import PresupuestoModel from '../models/presupuesto/presupuesto.model';

@Injectable({
  providedIn: 'root'
})
export class NavParamsService {
  
  cliente: ClienteModel;
  direccion: DireccionModel;
  linea: AlbaranLineasModel;
  lineaPresupuesto: LineaPresupuestoModel;
  direccionPresupuesto: DireccionPresupuestoModel;
  filtroPresupuesto: FiltroPresupuestoModel;
  mail: EmailModel;
  fichero: FicheroModel;
  presupuesto: PresupuestoModel;
  estado: EstadoPresupuestoModel;
  recoveryMail: string;
  recoveryHash: string;
  albaran: AlbaranModel;
  
  constructor() { }
  
  clear(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.cliente = null;
      this.direccion = null;
      this.linea = null;
      this.lineaPresupuesto = null;
      this.fichero = null;
      this.presupuesto = null;
      this.estado = null;
      this.recoveryMail = '';
      this.recoveryHash = '';
      this.albaran = null;
    });
  }
}
