import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import FicheroModel from 'src/app/models/ficheros/fichero.model';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class FicherosService {

  public api: ApiService;

  constructor(
    public http: HttpClient
  ) { }

  // public async subirFichero(formData: FormData): Promise<FicheroModel[]> {
  //   return await this.api.HttpPostConFormData('/ficheros/subirFichero', formData)
  // }

  public async getFichero(almacenId:number, id: number, contentDisposition?: string): Promise<FicheroModel[]> {
    return await this.api.HttpGet('/ficheros/getFichero', {
      almacenId: almacenId.toString(),
      ficheroId: id.toString(),
      contentDisposition: (contentDisposition != undefined && contentDisposition != null && contentDisposition.trim().length > 0 ? contentDisposition : 'inline')
    });
  }

  public async eliminarFicheros(ficheros: FicheroModel[]): Promise<boolean> {
    return await this.api.HttpPost('/ficheros/eliminarficheros', ficheros)
  }
  
  public async subirFichero(formData: FormData): Promise<FicheroModel[]>{
    return await this.api.HttpPostConFormData('/ficheros/subirfichero', formData)

  }

  public async confirmarFicherosTemporales(ficheros: FicheroModel[]): Promise<FicheroModel[]> {
   return this.api.HttpPost('/ficheros/confirmarficherostemporales', ficheros)
  }
}
