import { LocationStrategy } from '@angular/common';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Config, MenuController, NavController } from '@ionic/angular';

import UsuarioModel from './models/usuario/usuario.model';
import { AlertService } from './services/alert.service';
import { GlobalService } from './services/global.service';
import { NavParamsService } from './services/nav-params.service';
import { UtilService } from './services/util.service';
import { ApiService } from './api/api.service';
import { ToastService } from './services/toast.service';
import { LoaderService } from './services/loader.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {


  public displayingExitMessage: boolean;
  public version: string;
  public selectedIndex = 0;
  public ready: boolean;
  public user: UsuarioModel;
  public pages = [];
  public static instance: AppComponent

  constructor(
    private locationStrategy: LocationStrategy,
    public api: ApiService,
    public alertService: AlertService,
    public toastService: ToastService,
    public loaderService: LoaderService,
    private nav: NavController,
    private config: Config,
    private navParams: NavParamsService,
    private menuCtrl: MenuController,
    private router: Router,
    public updates: SwUpdate
  ) {
    AppComponent.instance = this
    this.ready = false;
    this.api.logged = false;
    this.displayingExitMessage = false;
    // check if back or forward button is pressed.
    this.locationStrategy.onPopState(() => {
      GlobalService.backClicked = true;
    });

    this.version = GlobalService.version;

  }

  async ngOnInit() {
    await this.initializeApp();
  }

  public async update() {
    try {
      this.updates.available.subscribe(async event => {
        if (event.current != event.available) {

          await this.updates.activateUpdate()
          document.location.reload()


        } else {
          this.loaderService.hide();
        }

      })

      await this.updates.checkForUpdate();

    } catch (ex) {
      console.error(ex)
      this.loaderService.hide();
    }
  }

  public realizarPresupuesto() {
    this.nav.navigateForward(`presupuestos-form/0`)
  }
  
  public executeMenu() {
    
    this.user = this.api.usuario

    this.pages = [
      {
        title: 'Nuevo presupuesto',
        url: '/presupuestos-form/0',
        icon: 'calculator-outline',
        visible: this.api.logged,
        execute: () => { },
        class: 'nuevopresupuesto'
      },
      {
        title: 'Inicio',
        url: '/home',
        icon: 'home-outline',
        visible: this.api.logged,
        execute: () => { }
      },
      {
        title: 'Presupuestos',
        url: '/presupuestos-list',
        icon: 'receipt-outline',
        visible: this.api.logged,
      },
      {
        title: 'Albaranes',
        url: '/albaranes-list',
        icon: 'receipt-outline',
        visible: this.api.logged,
      },
      {
        title: 'Nuevo presupuesto',
        url: '/presupuesto-form/0',
        icon: 'receipt'
      },
      {
        title: 'Clientes',
        url: '/clientes-list',
        icon: 'people-outline',
        visible: this.api.logged,
      },
      {
        title: 'Mi cuenta',
        url: '/mi-cuenta',
        icon: 'person-outline',
        visible: this.api.logged,
      }
    ]
    
  }

  async initializeApp() {

    this.api.loginToken = localStorage.getItem("token")
    
    await this.update();
    // await this.api.Login.getLogin()
    await this.executeMenu();


    GlobalService.readyFired = true;
    this.ready = true;

    let goTo = UtilService.queryString("goto");

    if (goTo != null && goTo.trim().length > 0) {
      this.navParams.recoveryMail = UtilService.queryString("usr")
      this.navParams.recoveryHash = UtilService.queryString("hash")
      this.nav.navigateRoot(goTo)
    } else {
      this.nav.navigateRoot('home');
    }

  }  

  public async nuevoPresupuesto() {

  }

  public async logOut() {
    let nombre = this.api.usuario.nombre;    
    await this.api.Login.logOut(false);
    if (nombre != null && nombre.trim().length > 0) {
      this.toastService.showToast(`Adiós ${nombre}!`)      
    }
    this.executeMenu();
    this.nav.navigateForward(`login`)
  }

  public goToRoot(page: string){
    this.menuCtrl.close();
    this.nav.navigateRoot(page);
  }

}
