import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private nombreAplicacion: string;
  private urlService: string;
  private urlIntranet: string;
  private urlApp: string;
  private pushPublicKey: string;

  constructor() {
    this.nombreAplicacion = 'Mudanzas En Marcha';

    //this.urlIntranet = 'http://localhost:50573/'; // Pruebas
    this.urlIntranet = 'https://erp.mudanzasenmarcha.com/'; // producción

    this.urlService = this.urlIntranet + 'api';
    this.pushPublicKey = "BJMKU8hhRDDlBfWrIPC7QUpwJ3o9gq1qZpIDdPRUCk14PrYD2k7rG8FW0feuwa1QQECsQoHn1DKDh3AcNrnd2RA";
  }

  public getPushPublicKey(): string {
    return this.pushPublicKey;
  }

  public getUrlService(noApi?: boolean): string {
    if (noApi !== undefined && noApi === true) {
      return this.urlIntranet;
    } else {
      return this.urlService;
    }
  }

  public getUrlApp(): string {
    return this.urlApp;
  }

  public getUrlIntranet(): string {
    return this.urlIntranet;
  }

  public getNombreAplicacion() {
    return this.nombreAplicacion;
  }

}
