import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import UsuarioModel from '../models/usuario/usuario.model';
import { GlobalService } from '../services/global.service';
import { NavParamsService } from '../services/nav-params.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivateChild {
  constructor(
    private service: ApiService,
    private router: Router,
    private navParams: NavParamsService,
    private api: ApiService,
    private menuController: MenuController
  ) {}

  //public isLoggedIn: boolean = false

  async canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

      if (this.api.logged) {
        return true
      }
      try {
        this.api.usuario = await this.service.Login.getCurrentUser()
        if (this.api.usuario != null) {
          this.api.logged = true;
          this.api.loginToken = this.api.usuario.token;
          this.menuController.enable(true)
          GlobalService.publishRecargarMenu();
        }else{
          throw new Error('No login')
        }
      } catch (ex) {
          this.router.navigate(['/login'], {
            queryParams: {
              returnURL: location.href
            }
          })
       }

      if (this.api.logged) {
        
        return true
        
      }

    this.router.navigate(['/login'], {
      queryParams: {
        returnURL: location.href
      }
    })
    return false;
  }
  
}
