import { Component, EventEmitter, Injectable, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil,
  takeWhile
} from "rxjs/operators";
import { OrientationComponent } from "../components/orientation/orientation.component";

@Injectable({
  providedIn: "root"
})
export class OrientationService {
  public currentOrientation$: Observable<OrientationType>;
  public lockedOrientation$ = new BehaviorSubject<OrientationType | null>(null);
  public lockIsReady$ = new BehaviorSubject<boolean>(false);

  private modalActive = false;
  private updateEvent = new EventEmitter();

  constructor(
    private modalController: ModalController,
    private platform: Platform
  ) {
    const events = [
      this.updateEvent,
      (screen.orientation ? fromEvent(screen.orientation, "change") : fromEvent(document, "visibilitychange"))
    ];

    // const events = [
    //   this.updateEvent,
    //   fromEvent(document, "visibilitychange"),
    //   fromEvent(document, "fullscreenchange"),
    //   fromEvent(window, "resize")
    // ];

    // if (screen.orientation) {
    //   events.push(fromEvent(screen.orientation, "change"));
    // }

    this.currentOrientation$ = merge(...events).pipe(
      debounceTime(200),
      map(() => this.checkOrientation())
    );
  }

  /**
   * Sets the orientation or triggers Fullscreen depending on support
   * @param orientation - The desired orientation
   */
  public async setOrientation(orientation: OrientationType): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.toggleScreenLock(orientation).then(locked => resolve(locked));
      // this.toggleFullScreen().then(fullscreen => {
      //   if (fullscreen) {
      //     this.toggleScreenLock(orientation).then(locked => resolve(locked));
      //   } else {
      //     resolve(false);
      //   }
      // });
    });
  }

  /**
   * Forces the user to rotate the screen to match the desired orientation
   * by showing a modal whenever the orientation doesn't match
   * and making use of Screen Orientation and Fullscreen API
   *
   * @param orientation - The orientation to lock to
   */
  public lock(orientation: OrientationType): void {
    this.lockedOrientation$.next(orientation);

    this.currentOrientation$
      .pipe(
        takeWhile(() => !!this.lockedOrientation$.value),
        distinctUntilChanged()
      )
      .subscribe({
        next: value => {
          // On a desktop there is no way to change the orientation
          // therefore the lock will always be ready

          if (this.platform.is("desktop")) {
            this.lockIsReady$.next(true);
          } else if (!this.modalActive) {
            if (value === this.lockedOrientation$.value) {
              this.lockIsReady$.next(true);
            } else {
              this.lockIsReady$.next(false);
              this.modalActive = true;
              this.showOrientationModal().then(() => {
                this.modalActive = false;
              });
            }
          }
        },
        complete: () => {
          this.lockIsReady$.next(false);
          if (screen.orientation && screen.orientation.unlock) {
            screen.orientation.unlock();
          }

          if (this.isFullscreenActive()) {
            document.exitFullscreen().catch(err => console.warn(err));
          }
        }
      });
    this.updateEvent.emit();
  }

  /**
   * Unlocks the screen if it has been locked
   */
  public unlock(): void {
    this.lockedOrientation$.next(null);
    this.updateEvent.emit();
  }

  /**
   * Calculates aspect ratio and returns OrientationType
   */
  private checkOrientation(): OrientationType {
    const aspectRatio = window.outerWidth / window.outerHeight;
    return aspectRatio >= 1 ? "landscape-primary" : "portrait-primary";
  }

  /**
   * Checks if fullscreen mode is active
   */
  private isFullscreenActive(): boolean {
    const doc = document as any;
    return (
      doc.fullscreenElement ||
      doc.webkitFullscreenElement ||
      doc.mozFullScreenElement
    );
  }

  /**
   * Sets the document view to fullscreen if possible
   */
  private async toggleFullScreen(): Promise<boolean> {
    const doc = document.documentElement as any;
    const rfs =
      doc.requestFullscreen ||
      doc.webkitRequestFullScreen ||
      doc.mozRequestFullScreen ||
      doc.msRequestFullscreen;

    return new Promise<boolean>(resolve => {
      if (rfs) {
        rfs.call(doc);
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }

  /**
   * Sets a Screen Orientation lock if possible
   *
   * @param orientation - the desired orientation
   */
  private async toggleScreenLock(
    orientation: OrientationType
  ): Promise<boolean> {
    const screen = window.screen as any;
    return new Promise<boolean>(resolve => {
      if (
        (screen.orientation || {}).type ||
        screen.mozOrientation ||
        screen.msOrientation
      ) {
        screen.orientation
          .lock(orientation)
          .then(() => resolve(true))
          .catch(() => resolve(false));
      } else {
        resolve(false);
      }
    });
  }

  /**
   * Shows a modal that requests the user to rotate the device
   */
  private async showOrientationModal(): Promise<boolean> {
    const modal = await this.modalController.create({
      component: OrientationComponent,
      backdropDismiss: false,
      animated: false,
      cssClass: "orientationModal"
    });

    await modal.present();

    return new Promise<boolean>(resolve => {
      modal
        .onDidDismiss()
        .then(() => resolve(true))
        .catch(() => resolve(false));
    });
  }
}

